import { logAmplitudeEvent } from 'chairisher/util/analytics';

/**
 * Tracks a blog page view
 *
 * @param {string} url
 */
export default function trackBlogScreen(url) {
    const getFirstCaptureFromUrl = (regex) => url.match(regex)?.[1];

    let eventName;
    const obj = {};

    if (/page/.test(url)) {
        obj.page = getFirstCaptureFromUrl(/\/page\/([0-9]+)/);
    }

    const bodyClassList = document.querySelector('body').classList;

    if (bodyClassList.contains('single-post')) {
        eventName = 'screen - blog post';
        obj.slug = getFirstCaptureFromUrl(/\/blog\/([\w-]+)([\w-]+)/);
    } else if (bodyClassList.contains('home')) {
        eventName = 'screen - blog homepage';
    } else if (bodyClassList.contains('category')) {
        eventName = 'screen - blog category';
        obj.slug = getFirstCaptureFromUrl(/\/blog\/category\/([\w-]+)([\w-]+)/);
    } else if (bodyClassList.contains('tag')) {
        eventName = 'screen - blog tags';
        obj.slug = getFirstCaptureFromUrl(/\/blog\/tag\/([\w-]+)([\w-]+)/);
    } else if (bodyClassList.contains('search-results')) {
        eventName = 'screen - blog search results';
        const query = new URLSearchParams(document.location.search).get('s');
        if (query) {
            obj.query = query;
        }
    }

    if (eventName) {
        logAmplitudeEvent(eventName, obj);
    }
}
