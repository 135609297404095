import $ from 'jquery'; // WordPress includes a different jquery. Webpack doesn't expose $ to window, so they coexist.

import BaseController from 'chairisher/_controller';

import trackBlogScreen from 'chairisher/analytics/blog';
import trackGlobalSearch from 'chairisher/analytics/globalsearch';
import bindInlineEmailSignup from 'chairisher/view/promo/inlineemailsignup';

import { trackSuccessfulEmailPanelCollection } from 'chairisher/analytics/email';

class BlogController extends BaseController {
    bindPageActions() {
        super.bindPageActions();

        $.ajax({
            url: '/assets-blog/account-nav',
            data: { next_url: document.location.pathname + document.location.search },
        }).done((data) => {
            $('.js-actions-account').html(data);
        });

        if ($('#js-chairish-inline-email-collector-form').length) {
            bindInlineEmailSignup({
                captureType: 'panel',
                formSelector: '#js-chairish-inline-email-collector-form',
                emailTrackingCallback: () => {
                    trackSuccessfulEmailPanelCollection();
                },
            });
        }
    }

    bindPageAnalytics() {
        super.bindPageAnalytics();

        trackBlogScreen(document.location.href);

        $('body').on('submit', '#header-search', (e) => {
            e.preventDefault();

            const $form = $(e.currentTarget);
            const formValue = $form.find('[name=s]').val() ?? '';

            trackGlobalSearch('blog masthead', formValue.trim());

            $form[0].submit();
        });
    }
}

export default BlogController;
